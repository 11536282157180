@charset "ISO-8859-1";

html {
  scroll-behavior: smooth!important;
  font-family: 'Square Peg', cursive!important;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}

#appbar {
  overflow: hidden!important;
  position: fixed!important; /* Set the navbar to fixed position */
  top: 0!important; /* Position the navbar at the top of the page */
  width: 100%!important; /* Full width */
}

#appbar .link {
  display: flex!important;
  color: #f2f2f2!important;
  text-align: center!important;
  padding: 14px 16px!important;
  text-decoration: none!important;
}

#appbar .link:hover {
  background: #ddd!important;
  color: rgb(80, 16, 16)!important;
}

.link:hover {
  color: #ddd!important;
  font-size: 41px!important;
  transition:ease-in
}

.loader {
  border: 5px solid #da4242;
  border-radius: 100%;
  border-top: 5px solid #d65f5f;
  width: 100px;
  height: 100px;
  text-align: center!important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  justify-content: center;
  
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bgimg-1, .bgimg-2, .bgimg-3 {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.bgimg-cover {
  min-height: 400px;
  position: relative;
  opacity: 0.9;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(80%);
}
.img-undo-effect{
  filter: grayscale(0%) blur(0px);
  position: absolute;
}

.bgimg-1 {
  min-height: 400px;
}

.bgimg-2 {
  filter: grayscale(80%);
  min-height: 400px;
}

.bgimg-3 {
  z-index: 1;
  min-height: 400px;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.border {
  background-color: rgb(8, 58, 94);
  color: #ffd54f!important;
  padding: 10px;
  font-size: 25px;
  letter-spacing: 10px;
  font-weight: 700;
}

.icon-animate:hover{
  animation: rotate 0.2s linear;
  color: #ffffff!important;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
}

.close-text-animate:hover{
  color: #ffffff!important;
}

/** CARD / IMAGE SLIDE UP/DOWN HOVER EFFECT **/
.img-container{
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(20, 29, 44);
  background-color: rgba(20, 29, 44, 0.9);
}

.img-container:hover .img-overlay {
  opacity: 1;
}

.img-overlay-text {
  position: absolute; 
  top: 0; 
  background: rgb(5, 9, 18);
  background: rgba(15, 24, 41, 0.9); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:1;
  height: 30%
}
.img-effect{
  -webkit-filter: grayscale(60%);
  filter: grayscale(60%)
  
}

.img-effect-secondary{
  -webkit-filter: blur(1px) grayscale(10%) saturate(1.4);
  filter: blur(1px) grayscale(10%) saturate(1.4);
  transition: .5s ease;
}

.menu-text:hover{
 color: rgb(207, 204, 204)
}

.hero-body {
  position: relative;
}

#tsparticles {
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 5%;
}

.non-particle{
  position: relative!important;
}

.site-text{
  font-family: 'Montserrat Alternates', sans-serif!important;
}
.landing-text{
  font-family: 'Fredericka the Great', cursive!important;
}

.sub-text{
  font-family: 'Black Ops One', cursive;
}

.other-text{
  font-family: 'Noto Sans', sans-serif!important;
}

.bg-gradient{
  background: rgb(19,29,50);
background: linear-gradient(90deg, rgb(24, 34, 56,1) 0%, rgba(20,25,34,0.9) 35%, rgba(20,25,34,1) 100%);
}

.secondary-gradient{
  background: rgb(180,58,58);
background: linear-gradient(90deg, rgba(180,58,58,1) 0%, rgba(253,183,29,1) 50%, rgba(252,115,69,1) 100%);
}

.secondary-text{
  background: -webkit-linear-gradient(rgb(255, 224, 25), rgb(222, 87, 42));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary-text{
  background: -webkit-linear-gradient(rgb(255, 224, 25), rgb(206, 114, 33));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

